import React from 'react';
import { Lexicon } from '@nitidbit/lexicon';
import text from './ProvidersStrings.json';
import Accordion from '../../shared/Accordion/Accordion';
import Markdown from '../../shared/Markdown';
import Page from '../../shared/Page/Page';
import PageTitle from '../../shared/PageTitle/PageTitle';
import VisitButton from '../../shared/VisitButton/VisitButton';
import withEditWrapper from '../../shared/WithEditWrapper';

import './Providers.scss';

import desktopMenu from './desktop-8-va-menu.jpg';
import desktopMenuPostpartum from './desktop-9-va-postpartum-menu.jpg';
import regularSummaryScreenshot from './summary_screenshot.png';
import postpartumSummaryScreenshot from './postpartum_summary_screenshot.png';

export const Providers = ({
  lexicon,
  sharedLexicon,
  setModalOnMode,
  mode = 'regular',
}) => {
  const summaryScreenshot =
    mode === 'postpartum'
      ? postpartumSummaryScreenshot
      : regularSummaryScreenshot;

  const buttonNames2 = [
    lexicon.get('section_title_2'),
    lexicon.get('section_title_3'),
    lexicon.get('section_title_4'),
    lexicon.get('section_title_5'),
  ];

  return (
    <Page pageName="Providers">
      <div className="column">
        <div className="row-or-column">
          <div className="content">
            <PageTitle text={lexicon.get('for_providers')} color="green" />
            <Markdown
              className="page-intro-large"
              text={lexicon.get('my_path')}
            />
          </div>

          <div className="mobile-tablet-only flexbox-center">
            <img
              className="desktop-menu"
              src={mode === 'postpartum' ? desktopMenuPostpartum : desktopMenu}
              alt="desktop menu"
            />
            <VisitButton
              mode={mode}
              clickHandler={() => setModalOnMode(mode)}
              lexicon={sharedLexicon.subset('VisitButton')}
            />
          </div>
        </div>
        <div className="content">
          <Markdown
            className="page-intro-text"
            text={lexicon.get('my_path_intro')}
          />
          <Accordion buttonNames={[lexicon.get('section_title_1')]}>
            <section className="accordion-section with-summary">
              <Markdown
                className="text-part"
                text={lexicon.get('section_list_markdown_1')}
              />
              <Markdown
                className="text-part"
                text={lexicon.get('section_summary_markdown_1')}
              />
              <a href="/MyPath_summary.pdf">
                <img src={summaryScreenshot} alt="summary example" />
              </a>
            </section>
          </Accordion>

          <Markdown
            className="page-intro-text"
            text={lexicon.get('providers_intro')}
          />
          <Accordion buttonNames={buttonNames2} startingPos={2}>
            <section className="accordion-section with-table">
              {lexicon.get('section_intro_markdown_2') && (
                <Markdown
                  className="text-part"
                  text={lexicon.get('section_intro_markdown_2')}
                />
              )}

              {lexicon.get('section_list_markdown_2') && (
                <Markdown
                  className="text-part"
                  text={lexicon.get('section_list_markdown_2')}
                />
              )}

              {lexicon.get('section_table_heading_2') && (
                <Markdown
                  className="table-heading"
                  text={lexicon.get('section_table_heading_2')}
                />
              )}

              {lexicon.get('section_table_markdown_2') && (
                <Markdown
                  className="table-body"
                  text={lexicon.get('section_table_markdown_2')}
                />
              )}

              {lexicon.get('section_table_footer_2') && (
                <Markdown
                  className="table-footer"
                  text={lexicon.get('section_table_footer_2')}
                />
              )}

              {lexicon.get('section_resource_intro_markdown_2') && (
                <Markdown
                  className="text-part list-intro"
                  text={lexicon.get('section_resource_intro_markdown_2')}
                />
              )}

              {lexicon.get('section_resource_list_markdown_2') && (
                <Markdown
                  className="text-part"
                  text={lexicon.get('section_resource_list_markdown_2')}
                />
              )}

              {lexicon.get('section_resource_intro_markdown_3') && (
                <Markdown
                  className="text-part list-intro"
                  text={lexicon.get('section_resource_intro_markdown_3')}
                />
              )}

              {lexicon.get('section_resource_list_markdown_3') && (
                <Markdown
                  className="text-part"
                  text={lexicon.get('section_resource_list_markdown_3')}
                />
              )}
            </section>

            <section className="accordion-section">
              <Markdown
                className={`text-part with-fertility ${mode}`}
                text={lexicon.get('section_intro_markdown_3')}
              />
              <Markdown
                className="text-part"
                text={lexicon.get('section_list_markdown_3')}
              />
            </section>

            <section className="accordion-section">
              <Markdown
                className={`text-part with-topics ${mode}`}
                text={lexicon.get('section_intro_markdown_4')}
              />
              <Markdown
                className="text-part"
                text={lexicon.get('section_list_markdown_4')}
              />
            </section>

            <section className="accordion-section">
              <Markdown
                className="text-part"
                text={lexicon.get('section_intro_markdown_5')}
              />
              <Markdown
                className="text-part"
                text={lexicon.get('section_list_markdown_5')}
              />
            </section>
          </Accordion>
        </div>
      </div>
      <div className="desktop-only flexbox-center">
        <img
          src={mode === 'postpartum' ? desktopMenuPostpartum : desktopMenu}
          alt="desktop menu"
          className="desktop-menu"
        />
        <VisitButton
          mode={mode}
          clickHandler={() => setModalOnMode(mode)}
          lexicon={sharedLexicon.subset('VisitButton')}
        />
      </div>
    </Page>
  );
};

const ProvidersLexicon = new Lexicon(
  text,
  'en',
  'src/pages/Providers/ProvidersStrings.json'
);

export default withEditWrapper(Providers, ProvidersLexicon);
