import React from 'react';
import './Footer.scss';
import UCSFIcon from './ucsf-logo@2x.png';
import UWIcon from './UW-logo@2x.png';
import VAIcon from './VA-logo@2x.png';

const Footer = () => (
  <div id="Footer">
    <img src={VAIcon} alt="VA logo" />
    <img src={UWIcon} alt="UW logo" />
    <img src={UCSFIcon} alt="UCSF logo" />
  </div>
);


export default Footer;
