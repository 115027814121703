import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import RainbowBar from '../RainbowBar/RainbowBar';
import './Hamburger.scss';

import HamburgerIcon from './Hamburger.png';
import CloseIcon from './Close.png';
import Logo from '../../assets/images/MyPath@2x.png';

const Hamburger = ({ isHamburgerOpen, setIsHamburgerOpen, children }) => {
  const stateChangeHandler = (state) => {
    if (state.isOpen !== isHamburgerOpen) setIsHamburgerOpen(state.isOpen);
  };

  return (
    <div id="Hamburger">
      <Menu
        customBurgerIcon={<img src={HamburgerIcon} alt="Hamburger" />}
        customCrossIcon={<img src={CloseIcon} alt="Close" />}
        burgerButtonClassName="custom-button"
        crossButtonClassName="custom-cross-button"
        menuClassName="custom-menu"
        pageWrapId="dummyItem"
        outerContainerId="Hamburger"
        width="100%"
        isOpen={isHamburgerOpen}
        onStateChange={stateChangeHandler}
        right
      >
        <div className="mybc-item-list">
          <div className="top-bar fixed">
            <RainbowBar />
          </div>

          <div className="mybc in-menu">
            <img src={Logo} alt="My Path" />
          </div>

          <div className="menu-item-container">{children}</div>
        </div>
        <RainbowBar size="small" />
      </Menu>
      <div id="dummyItem" />
    </div>
  );
};

export default Hamburger;
