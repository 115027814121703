import React from 'react';
import Markdown from '../../shared/Markdown';

import './ModalButton.scss';
import './CollaborationSection.scss';

const CollaborationSection = ({
  index,
  markdown,
  buttonText,
  url,
  children,
}) => (
  <div className={`CollaborationSection sec-${index}`}>
    <div>
      <Markdown className="text-part" text={markdown} />
      <a className="ModalButton" href={url} target="_blank" rel="noreferrer">
        {buttonText}
      </a>
    </div>

    <div className="icon-container">{children}</div>
  </div>
);

export default CollaborationSection;
