import React from 'react';
import LinkPill from '../LinkPill/LinkPill';
import ModalWithOverlay from '../Modal/ModalWithOverlay';

import './LinkModal.scss';

const linkConfig = {
  regular: [
    {
      text: 'MyPath',
      link: 'http://www.mypathtool.org/',
    },
    {
      text: 'MyPath for Veterans',
      link: 'http://va.mypathtool.org/',
    },
  ],
  postpartum: [
    {
      text: 'MyPath Postpartum',
      link: 'http://postpartum.mypathtool.org/',
    },
    {
      text: 'MyPath Postpartum for Veterans',
      link: 'https://va-postpartum.mypathtool.org',
    },
  ],
};

const LinkModal = ({ modalOnMode, modalClickHandler, lexicon }) => (
  <ModalWithOverlay
    clickHandler={modalClickHandler}
    modalOnMode={modalOnMode}
    closeBox="close box"
  >
    <div className="modal-content">
      <p>{lexicon.get(`modal_text_${modalOnMode}`)}</p>
      <div className="link-container">
        {linkConfig[modalOnMode].map(({ text, link }) => (
          <LinkPill key={link} link={link} text={text} />
        ))}
      </div>
    </div>
  </ModalWithOverlay>
);

export default LinkModal;
