import React from 'react';
import './Page.scss';

const Page = ({ pageName, children }) => (
  <div className="app-background" id={pageName}>
    { children }
  </div>
);

export default Page;
