import React from 'react';
import { Lexicon } from '@nitidbit/lexicon';
import text from './VeteransStrings.json';
import Accordion from '../../shared/Accordion/Accordion';
import Markdown from '../../shared/Markdown';
import Page from '../../shared/Page/Page';
import PageTitle from '../../shared/PageTitle/PageTitle';
import withEditWrapper from '../../shared/WithEditWrapper';

import portraitImg from './Alicia-Christy-Fellow-Portrait.jpg';

import './Veterans.scss';

const Veterans = ({ lexicon }) => {
  const buttonNames = [
    lexicon.get('section_title_1'),
    lexicon.get('section_title_6'),
  ];

  return (
    <Page pageName="Veterans">
      <div className="column">
        <div className="row-or-column">
          <div className="content">
            <PageTitle text={lexicon.get('for_veterans')} color="blue" />
            <Markdown
              className="page-intro-large"
              text={lexicon.get('needs')}
            />
          </div>

          <div className="mobile-tablet-only img-container">
            <img src={portraitImg} alt="family portrait" />
            <p>{lexicon.get('portrait_text')}</p>
          </div>
        </div>
        <div className="content">
          <Markdown
            className="page-intro-text"
            text={lexicon.get('veterans_intro')}
          />

          <Accordion buttonNames={buttonNames}>
            <section className="accordion-section">
              <Markdown
                className="text-part"
                text={lexicon.get('section_va_list_markdown_1')}
              />
              <Markdown
                className="text-part"
                text={lexicon.get('section_resource_list_markdown_1')}
              />
            </section>

            <section className="accordion-section with-table">
              <Markdown
                className="text-part"
                text={lexicon.get('section_intro_markdown_6')}
              />
              <Markdown
                className="table-body"
                text={lexicon.get('section_table_markdown_6')}
              />
              <Markdown
                className="text-part list-intro"
                text={lexicon.get('section_resource_intro_markdown_6')}
              />
              <Markdown
                className="text-part"
                text={lexicon.get('section_manuscript_list_markdown_6')}
              />
              <Markdown
                className="text-part"
                text={lexicon.get('section_health_list_markdown_6')}
              />
            </section>
          </Accordion>
        </div>
      </div>
      <div className="desktop-only img-container">
        <img src={portraitImg} alt="family portrait" />
        <p>{lexicon.get('portrait_text')}</p>
      </div>
    </Page>
  );
};

const VeteransLexicon = new Lexicon(
  text,
  'en',
  'src/pages/Veterans/VeteransStrings.json'
);

export default withEditWrapper(Veterans, VeteransLexicon);
