import React from 'react';
import './ModalWithOverlay.scss';

const stopBubbling = (e) => {
  e.stopPropagation();
};

const CloseButton = ({ clickHandler, isButtonInTabDisabled = false, str }) => (
  <button
    className="close-x"
    tabIndex="0"
    onClick={clickHandler}
    disabled={isButtonInTabDisabled}
    type="button"
  >
    {str}
  </button>
);

const ModalWithOverlay = ({
  id = null,
  modalOnMode,
  children,
  closeBox,
  showCloseButton = true,
  clickHandler,
  extraClass = '',
  modalLabel = null,
}) => (
  <div
    id={id}
    role="presentation"
    className={`overlay ${modalOnMode ? '' : 'hidden'}`}
    onClick={(e) => {
      e.preventDefault();
      clickHandler();
    }}
  >
    <div
      className={`modal ${extraClass}`}
      onClick={stopBubbling}
      aria-label={modalLabel}
      aria-labelledby={id ? `${id}-modal-title` : null}
    >
      {children}

      {showCloseButton && (
        <CloseButton clickHandler={clickHandler} str={closeBox} />
      )}
    </div>
  </div>
);

export default ModalWithOverlay;
