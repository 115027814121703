import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { EditWrapper, Lexicon } from '@nitidbit/lexicon';
import history from './lib/history';
import sharedStrings from './shared/sharedStrings.json';
import { LEXICON_URL } from './constants';

import { isInEditMode } from './shared/WithEditWrapper';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import EditablePatients from './pages/Patients/Patients';
import PatientsPostpartum from './pages/Patients/PatientsPostpartum';
import EditableProviders from './pages/Providers/Providers';
import ProvidersPostpartum from './pages/Providers/ProvidersPostpartum';
import Veterans from './pages/Veterans/Veterans';
import RainbowBar from './shared/RainbowBar/RainbowBar';
import HeaderNav from './shared/HeaderNav/HeaderNav';
import Footer from './shared/Footer/Footer';
import LinkModal from './shared/LinkModal/LinkModal';

import './App.scss';

const unlozalizedSharedLexicon = new Lexicon(
  sharedStrings,
  'en',
  'src/sharedStrings.json'
);

const App = ({ sharedLexicon = unlozalizedSharedLexicon }) => {
  const [modalOnMode, setModalOnMode] = useState(null);

  return (
    <BrowserRouter history={history}>
      <RainbowBar />
      <HeaderNav sharedLexicon={sharedLexicon} />

      <Routes>
        <Route
          exact
          path="/"
          element={
            <Home
              sharedLexicon={sharedLexicon}
              setModalOnMode={setModalOnMode}
            />
          }
        />

        <Route
          path="/patients-regular"
          element={
            <EditablePatients
              sharedLexicon={sharedLexicon}
              setModalOnMode={setModalOnMode}
            />
          }
        />

        <Route
          path="/patients-postpartum"
          element={
            <PatientsPostpartum
              sharedLexicon={sharedLexicon}
              setModalOnMode={setModalOnMode}
            />
          }
        />

        <Route
          path="/providers-regular"
          element={
            <EditableProviders
              sharedLexicon={sharedLexicon}
              setModalOnMode={setModalOnMode}
            />
          }
        />

        <Route
          path="/providers-postpartum"
          element={
            <ProvidersPostpartum
              sharedLexicon={sharedLexicon}
              setModalOnMode={setModalOnMode}
            />
          }
        />

        <Route path="/about" element={<About />} />

        <Route path="/veterans" element={<Veterans />} />
      </Routes>

      {modalOnMode && (
        <LinkModal
          lexicon={sharedLexicon.subset('LinkModal')}
          modalOnMode={modalOnMode}
          modalClickHandler={() => setModalOnMode(null)}
        />
      )}
      <Footer />
    </BrowserRouter>
  );
};

const AppWithNewLexicon = ({ lexicon }) => {
  const { _contentByLocale } = lexicon;
  const newLexicon = new Lexicon(
    _contentByLocale,
    'en',
    'src/sharedStrings.json'
  );

  return <App sharedLexicon={newLexicon} />;
};

const EditableApp = () => (
  <EditWrapper
    apiUpdateUrl={LEXICON_URL}
    lexicon={unlozalizedSharedLexicon}
    component={AppWithNewLexicon}
  />
);

const AppToRender = isInEditMode ? EditableApp : App;

export default AppToRender;
