import React, { lazy, Suspense } from 'react';
import { LEXICON_URL } from '../constants';

const EditWrapper = lazy(() => import('@nitidbit/lexicon/js/EditWrapper'));

export const isInEditMode =
  decodeURIComponent(
    (window.location.search.match(
      /[?|&]lexiconServerToken=([^&;]+?)(&|#|;|$)/g
    ) || [null, ''])[1].replace(/\+/g, '%20')
  ) || sessionStorage.lexiconServerToken;

const conditionallyLocalizeLexicon = (lexicon, newLocale) =>
  lexicon.currentLocaleCode === newLocale
    ? lexicon
    : lexicon.locale(newLocale || 'en');

const withEditWrapper = (InnerComponent, pageLexicon) => {
  if (!isInEditMode) {
    /* [Dev Note]: this complex lexicon below is to enable each wrapped page to separately lazy load and supply its own page lexicon,
      while at the same time allows the homepage (PageLanding) to not-lazy load its page lexicon together with sharedLexicon, so we can improve page laoding speed of the homepage
    */
    const LocalizedComponent = (props) => (
      <InnerComponent
        {...props}
        lexicon={
          pageLexicon
            ? conditionallyLocalizeLexicon(pageLexicon, props.locale)
            : props.lexicon
        }
      />
    );

    return LocalizedComponent;
  }

  /* [Dev Note]: in edit mode, the component receives new lexicons from EditWrapper, we just need to tell EditWrapper where does page lexicon come from */
  const WithEditWrapper = (props) => (
    <Suspense fallback={null}>
      <EditWrapper
        apiUpdateUrl={LEXICON_URL}
        lexicon={
          pageLexicon ? pageLexicon.locale(props.locale || 'en') : props.lexicon
        }
        component={(wrapperProps) => (
          <InnerComponent
            {...props}
            lexicon={wrapperProps.lexicon.locale(props.locale || 'en')}
          />
        )}
      />
    </Suspense>
  );

  return WithEditWrapper;
};

export default withEditWrapper;
