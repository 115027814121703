import React from 'react';
import './LinkPill.scss';

const LinkPill = ({ link, text }) => (
  <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    className="link"
  >
    {text}
  </a>
);

export default LinkPill;
