import React from 'react';
import { Link } from 'react-router-dom';

import './NavDropdown.scss';

const NavDropdown = ({
  routeKey,
  lexicon,
  underbarPosition,
  navDropdownOpenType,
  setNavDropdownOpenType,
  handleHamburgerClosing,
  dropdownConfig,
}) => {
  const dropdownToggleClass =
    navDropdownOpenType === routeKey ? 'open' : 'closed';

  return (
    <li key={routeKey} className="NavDropdown">
      <div
        className={`${routeKey}-link ${dropdownToggleClass} ${underbarPosition}`}
        onFocus={() => {
          if (window.innerWidth >= 768) {
            setNavDropdownOpenType(routeKey);
          }
        }}
        onMouseEnter={() => {
          if (window.innerWidth >= 768) {
            setNavDropdownOpenType(routeKey);
          }
        }}
        onClick={() => {
          const openType = navDropdownOpenType === routeKey ? null : routeKey;
          setNavDropdownOpenType(openType);
        }}
      >
        {lexicon.get(routeKey)}
      </div>

      <hr className="mobile-only" />

      <ul
        className={`nav-dropdown-list ${dropdownToggleClass}`}
        role="menu"
        onMouseLeave={() => {
          setNavDropdownOpenType(null);
        }}
      >
        {Object.entries(dropdownConfig).map(([key, text]) => (
          <Link
            className="sub-link"
            to={`/${key}`}
            id={key}
            key={key}
            onClick={handleHamburgerClosing}
          >
            {text}
          </Link>
        ))}
      </ul>
    </li>
  );
};

export default NavDropdown;
