import React from 'react';
import { Lexicon } from '@nitidbit/lexicon';
import text from './PatientsStrings.json';
import FlowChart from './FlowChart';
import Page from '../../shared/Page/Page';
import Markdown from '../../shared/Markdown';
import Accordion from '../../shared/Accordion/Accordion';
import PageTitle from '../../shared/PageTitle/PageTitle';
import VisitButton from '../../shared/VisitButton/VisitButton';
import withEditWrapper from '../../shared/WithEditWrapper';

import './Patients.scss';

import tabletMenu from './tablet-menu.jpg';
import tabletMenuPostpartum from './tablet-menu-postpartum.jpg';

export const Patients = ({
  lexicon,
  sharedLexicon,
  setModalOnMode,
  mode = 'regular',
}) => {
  const buttonNames =
    mode === 'postpartum'
      ? []
      : [lexicon.get('section_title_3'), lexicon.get('section_title_4')];

  const flowChartTexts = [
    lexicon.get('section_list_markdown_1'),
    lexicon.get('section_list_markdown_2'),
    lexicon.get('section_list_markdown_3'),
    lexicon.get('section_list_markdown_4'),
    lexicon.get('section_list_markdown_5'),
  ];

  return (
    <Page pageName="Patients">
      <div className="column">
        <div className="row-or-column">
          <div className="content">
            <PageTitle text={lexicon.get('for_patients')} color="orange" />
            <Markdown
              className="page-intro-large"
              text={lexicon.get('choosing')}
            />
          </div>
          <div className="mobile-tablet-only flexbox-center">
            <img
              className="tablet-menu"
              alt="tablet menu"
              src={mode === 'postpartum' ? tabletMenuPostpartum : tabletMenu}
            />
            <VisitButton
              mode={mode}
              clickHandler={() => setModalOnMode(mode)}
              lexicon={sharedLexicon.subset('VisitButton')}
            />
          </div>
        </div>
        <div className="content">
          <Markdown
            className="page-intro-text"
            text={lexicon.get('section_intro_markdown_1')}
          />
          <FlowChart
            boxColors={[
              'light-orange',
              'dark-orange',
              'light-green',
              'dark-green',
              'light-blue',
            ]}
            texts={flowChartTexts}
          />
          <Markdown
            className="page-intro-text"
            text={lexicon.get('section_selective_markdown_2')}
          />
          {/* the Accordion component expects only "accordion-section" as children and also use buttonNames prop to determine how many buttons to inject */}
          {mode === 'regular' && (
            <Accordion buttonNames={buttonNames}>
              <section className="accordion-section">
                <Markdown
                  className="text-part list-intro"
                  text={lexicon.get('section_lingo_intro_markdown_3')}
                />
                <Markdown
                  className="text-part"
                  text={lexicon.get('section_lingo_list_markdown_3')}
                />
              </section>
              <section className="accordion-section">
                <Markdown
                  className="text-part list-intro"
                  text={lexicon.get('section_learn_intro_markdown_4')}
                />
                <Markdown
                  className="text-part"
                  text={lexicon.get('section_learn_list_markdown_4')}
                />
              </section>
            </Accordion>
          )}
        </div>
      </div>

      <div className="desktop-only flexbox-center">
        <img
          className="tablet-menu"
          src={mode === 'postpartum' ? tabletMenuPostpartum : tabletMenu}
          alt="desktop menu"
        />
        <VisitButton
          mode={mode}
          clickHandler={() => setModalOnMode(mode)}
          lexicon={sharedLexicon.subset('VisitButton')}
        />
      </div>
    </Page>
  );
};

const PatientsLexicon = new Lexicon(
  text,
  'en',
  'src/pages/Patients/PatientsStrings.json'
);

export default withEditWrapper(Patients, PatientsLexicon);
