import React from 'react';
import { Link } from 'react-router-dom';
import NavDropdown from '../NavDropdown/NavDropdown';

const MenuLinks = ({
  lexicon,
  underbarPosition,
  navDropdownOpenType,
  setNavDropdownOpenType,
  setIsHamburgerOpen,
}) => {
  const handleHamburgerClosing = () => {
    if (setIsHamburgerOpen) setIsHamburgerOpen(false);
  };

  return (
    <div role="presentation">
      <Link
        to="/"
        className={`home-link ${underbarPosition}`}
        id="home"
        onClick={handleHamburgerClosing}
      >
        {lexicon.get('home')}
        <hr className="mobile-only" />
      </Link>
      <NavDropdown
        routeKey="patients"
        lexicon={lexicon}
        underbarPosition={underbarPosition}
        navDropdownOpenType={navDropdownOpenType}
        setNavDropdownOpenType={setNavDropdownOpenType}
        handleHamburgerClosing={handleHamburgerClosing}
        dropdownConfig={{
          'patients-regular': 'MyPath',
          'patients-postpartum': 'MyPath Postpartum',
        }}
      />
      <NavDropdown
        routeKey="providers"
        lexicon={lexicon}
        underbarPosition={underbarPosition}
        navDropdownOpenType={navDropdownOpenType}
        setNavDropdownOpenType={setNavDropdownOpenType}
        handleHamburgerClosing={handleHamburgerClosing}
        dropdownConfig={{
          'providers-regular': 'MyPath',
          'providers-postpartum': 'MyPath Postpartum',
        }}
      />
      <Link
        to="/veterans"
        className={`veteran-link ${underbarPosition}`}
        id="veterans"
        onClick={handleHamburgerClosing}
      >
        {lexicon.get('veterans')}
        <hr className="mobile-only" />
      </Link>
      <Link
        to="/about"
        className={`about-link ${underbarPosition}`}
        id="about"
        onClick={handleHamburgerClosing}
      >
        {lexicon.get('about')}
        <hr className="mobile-only" />
      </Link>
    </div>
  );
};

export default MenuLinks;
