import React, { useState, useEffect, createRef, Children } from 'react';
import './Accordion.scss';

const Accordion = ({ children, buttonNames, buttonClasses = [], startingPos = 1 }) => {
  const refArr = new Array(children.length).fill(0).map(() => createRef());

  const [openSection, setOpenSection] = useState(null);
  const [scrollTo, setScrollTo] = useState(null);

  const buttonColor = (i) => (buttonClasses[i] || `button${i + startingPos}`);

  const accordionButtonClickHandler = (sectionNum) => {
    if (sectionNum === openSection) {
      setOpenSection(null);
      setScrollTo(null);
    } else {
      setOpenSection(sectionNum);
      setScrollTo(sectionNum);
    }
  };

  useEffect(() => {
    if (scrollTo !== null) window.scrollTo(0, refArr[scrollTo].current.offsetTop);
  }, [scrollTo]);

  return (
    <div id="Accordion">
      {
        Children.toArray(children).map((child, index) => {
          const buttonClass = `button ${buttonColor(index)} ${openSection === index ? 'active' : ''}`;
          const arrowClass = (openSection === index) ? 'arrow-up' : 'arrow-down';
          const sectionClass = `panel section${index + 1} ${(openSection === index ? 'active' : '')}`;

          return (
            <div className="accordion-button-and-section" key={buttonNames[index]}>
              <button
                ref={refArr[index]}
                className={buttonClass}
                onClick={() => { accordionButtonClickHandler(index); }}
                type="button"
              >
                { buttonNames[index] }
                <div className={arrowClass} />
              </button>
              <div className={sectionClass}>
                { child }
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default Accordion;
