import React from 'react';

import './VisitButton.scss';

const VisitButton = ({ lexicon, mode, clickHandler }) => (
  <button id="VisitButton" onClick={clickHandler} type="button">
    <div className="visit-text">{lexicon.get(`text_${mode}`)}</div>
    <div className="arrow-right wiggle" />
  </button>
);

export default VisitButton;
