import React from 'react';
import markdownIt from 'markdown-it';

const md = markdownIt({
  html: true,
});
const getMarkdownText = (text) => {
  const rawMarkup = md.render(text);
  return { __html: rawMarkup };
};

const Markdown = ({ className, text }) => (
  <div className={className} dangerouslySetInnerHTML={getMarkdownText(text)} />
);

export default Markdown;
