import React from 'react';
import { Lexicon } from '@nitidbit/lexicon';
import text from './ProvidersPostpartumStrings.json';
import { Providers } from './Providers';
import withEditWrapper from '../../shared/WithEditWrapper';

const PatientsPostpartumLexicon = new Lexicon(
  text,
  'en',
  'src/pages/Providers/ProvidersPostpartumStrings.json'
);

const ProvidersPostpartum = (props) => (
  <Providers mode="postpartum" {...props} />
);

export default withEditWrapper(ProvidersPostpartum, PatientsPostpartumLexicon);
