import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Hamburger from '../Hamburger/Hamburger';
import MenuLinks from '../MenuLinks/MenuLinks';

import './HeaderNav.scss';

import Logo from '../../assets/images/MyPath@2x.png';

const HeaderNav = ({ sharedLexicon }) => {
  const [navDropdownOpenType, setNavDropdownOpenType] = useState(null);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  const { pathname } = useLocation();
  const underbarPosition = pathname === '/' ? 'home' : pathname.slice(1);

  return (
    <div id="HeaderNav">
      <Hamburger
        isHamburgerOpen={isHamburgerOpen}
        setIsHamburgerOpen={setIsHamburgerOpen}
      >
        <MenuLinks
          lexicon={sharedLexicon.subset('MenuLinks')}
          underbarPosition={underbarPosition}
          navDropdownOpenType={navDropdownOpenType}
          setNavDropdownOpenType={setNavDropdownOpenType}
          setIsHamburgerOpen={setIsHamburgerOpen}
        />
      </Hamburger>

      <div className="title">
        <img src={Logo} alt="My Path" />
      </div>

      <div className="menu desktop-tablet-only">
        <MenuLinks
          lexicon={sharedLexicon.subset('MenuLinks')}
          underbarPosition={underbarPosition}
          navDropdownOpenType={navDropdownOpenType}
          setNavDropdownOpenType={setNavDropdownOpenType}
        />
        <hr className={underbarPosition} />
      </div>
    </div>
  );
};

export default HeaderNav;
