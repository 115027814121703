import React from 'react';
import { Lexicon } from '@nitidbit/lexicon';
import Page from '../../shared/Page/Page';
import VisitButton from '../../shared/VisitButton/VisitButton';
import text from './HomeStrings.json';
import Carousel from './Carousel';
import Markdown from '../../shared/Markdown';
import withEditWrapper from '../../shared/WithEditWrapper';

import './Home.scss';

const Home = ({ lexicon, sharedLexicon, setModalOnMode }) => (
  <Page pageName="Home">
    <div className="column">
      <div className="row-or-column">
        <div className="content">
          <Markdown
            className="introduction"
            text={lexicon.get('introducing')}
          />
          <Markdown className="page-intro-large" text={lexicon.get('a_tool')} />
        </div>
        <Carousel className="mobile-tablet-only" />
      </div>
      <div className="content">
        <Markdown className="page-intro-text" text={lexicon.get('list')} />
        <VisitButton
          mode="regular"
          clickHandler={() => setModalOnMode('regular')}
          lexicon={sharedLexicon.subset('VisitButton')}
        />

        <Markdown
          className="page-intro-text"
          text={lexicon.get('list_postpartum')}
        />
        <VisitButton
          mode="postpartum"
          clickHandler={() => setModalOnMode('postpartum')}
          lexicon={sharedLexicon.subset('VisitButton')}
        />

        <div className="disclosure">
          <Markdown text={lexicon.get('disclosure')} />
          <Markdown text={lexicon.get('privacy')} />
        </div>
      </div>
    </div>

    <Carousel className="desktop-only carousel carousel-desktop" />
  </Page>
);

const HomeLexicon = new Lexicon(text, 'en', 'src/pages/Home/HomeStrings.json');

export default withEditWrapper(Home, HomeLexicon);
