import React from 'react';
import { Lexicon } from '@nitidbit/lexicon';
import text from './PatientsPostpartumStrings.json';
import { Patients } from './Patients';
import withEditWrapper from '../../shared/WithEditWrapper';

const PatientsPostpartumLexicon = new Lexicon(
  text,
  'en',
  'src/pages/Patients/PatientsPostpartumStrings.json'
);

const PatientsPostpartum = (props) => <Patients mode="postpartum" {...props} />;

export default withEditWrapper(PatientsPostpartum, PatientsPostpartumLexicon);
