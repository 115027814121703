import React from 'react';
import './RainbowBar.scss';

const RainbowBar = ({ size = '' }) => (
  <div id="RainbowBar" className={size}>
    <div className="light-orange" />
    <div className="orange" />
    <div className="light-green" />
    <div className="green" />
    <div className="light-blue" />
    <div className="blue" />
  </div>
);

export default RainbowBar;