import React from 'react';
import './Carousel.scss';
import Slider from 'react-slick';

import screen1 from './ipad-1-mypathtool-splash.jpg';
import screen2 from './ipad-2-mypathtool-menu.jpg';
import screen3 from './ipad-3-mypathtool-methods.jpg';
import screen4 from './ipad-4-postpartum-splash.jpg';
import screen5 from './ipad-5-postpartum-menu.jpg';

const settings = {
  infinite: true,
  slidesToShow: 1,
  fade: true,
  speed: 500,
  autoplaySpeed: 5000,
  cssEase: 'linear',
  autoplay: true,
  arrows: false,
  draggable: false,
};

const Carousel = ({ className = '' }) => (
  <div id="Carousel" className={className}>
    <Slider {...settings}>
      <img src={screen1} alt="MyPath pic 1" />
      <img src={screen2} alt="MyPath pic 2" />
      <img src={screen3} alt="MyPath pic 3" />
      <img src={screen4} alt="MyPath pic 4" />
      <img src={screen5} alt="MyPath pic 5" />
    </Slider>
  </div>
);

export default Carousel;
