import React, { Fragment } from 'react';
import './FlowChart.scss';
import Markdown from '../../shared/Markdown';

const FlowChart = ({ boxColors, texts }) => (
  <div className="flow-container">
    {
      texts.map((text, i) => (
        <Fragment key={text}>
          { i !== 0 && <div className="arrow" /> }
          <div className="flowchart">
            <div className={`${boxColors[i]} rectangle`} />
            <Markdown className="flowchart-text textPart" text={texts[i]} />
          </div>
        </Fragment>

      ))
    }
  </div>
);

export default FlowChart;
