import React from 'react';
import { Lexicon } from '@nitidbit/lexicon';
import text from './AboutStrings.json';
import CollaborationSection from './CollaborationSection';
import Accordion from '../../shared/Accordion/Accordion';
import Markdown from '../../shared/Markdown';
import Page from '../../shared/Page/Page';
import PageTitle from '../../shared/PageTitle/PageTitle';
import withEditWrapper from '../../shared/WithEditWrapper';

import women4WomenImg from './women_4_women.png';
import UCSFIconColoredImg from './UCSF_icon_colored.png';
import UWIconColoredImg from './UW_icon_colored.png';
import VAIconColoredImg from './VA_icon_colored.png';
import nitidIconImg from './nitid.png';
import COINIconImg from './COIN_logo.png';
import McgeeIconImg from './mcgeeLogo.jpg';
import IUIconImg from './IU_logo.jpeg';
import Path4YouIconImg from './path4you_logo.png';
import CONVERGEIconImg from './CONVERGE_logo.png';
import UOPIconImg from './UOP.png';
import HFHSIconImg from './HFHS.png';
import BFDIconImg from './BFD.png';
import CHERPIconImg from './CHERPlogo.jpg';

import './About.scss';

const About = ({ lexicon }) => {
  const buttonNames = [
    lexicon.get('section_title_1'),
    lexicon.get('section_title_2'),
    lexicon.get('section_title_3'),
  ];

  return (
    <Page pageName="About">
      <div className="content">
        <PageTitle text={lexicon.get('about_us')} color="dark-blue" />
        <Markdown
          className="page-intro-text"
          text={lexicon.get('about_text')}
        />

        <div className="mobile-tablet-only">
          <div className="image-container">
            <img
              className="partnership-image"
              src={VAIconColoredImg}
              alt="VA logo"
            />
            <img
              className="partnership-image"
              src={UWIconColoredImg}
              alt="UW logo"
            />
            <img
              className="partnership-image"
              src={UCSFIconColoredImg}
              alt="UCSF logo"
            />
            <img
              className="credits-image"
              src={CONVERGEIconImg}
              alt="Converge logo"
            />
            <img className="credits-image" src={COINIconImg} alt="COIN logo" />
            <img
              className="credits-image"
              src={CHERPIconImg}
              alt="CHERP logo"
            />
            <img
              className="credits-image"
              src={women4WomenImg}
              alt="Women 4 women veterans logo"
            />
            <img
              className="partnership-image"
              id="nitid-logo"
              src={nitidIconImg}
              alt="Nitid logo"
            />
          </div>
        </div>

        <Accordion buttonNames={buttonNames}>
          <section className="accordion-section column">
            <CollaborationSection
              index="2"
              markdown={lexicon.get('section_reproductive_equity_markdown_1')}
              buttonText={lexicon.get('details_button')}
              url="https://www.henryford.com/hcp/research/we-care"
            >
              <img src={HFHSIconImg} alt="HFHS logo" />
              <img src={BFDIconImg} alt="BFD logo" />
            </CollaborationSection>

            <hr />

            <CollaborationSection
              index="3"
              markdown={lexicon.get(
                'section_postparum_with_substance_use_markdown_1'
              )}
              buttonText={lexicon.get('details_button')}
              url="https://clinicaltrials.gov/study/NCT04939012"
            >
              <img src={McgeeIconImg} id="mcgee-icon" alt="Mcgee logo" />
              <img src={UOPIconImg} id="uop-icon" alt="UOP logo" />
            </CollaborationSection>

            <hr />

            <CollaborationSection
              index="4"
              markdown={lexicon.get('section_path4you_markdown_1')}
              buttonText={lexicon.get('details_button')}
              url="https://path4you.org"
            >
              <img src={IUIconImg} id="iu-icon" alt="IU logo" />
              <img
                src={Path4YouIconImg}
                id="path4you-icon"
                alt="Path 4 You logo"
              />
            </CollaborationSection>
          </section>
          <section className="accordion-section">
            <Markdown
              className="text-part list-intro"
              text={lexicon.get('section_intro_markdown_2')}
            />
            <Markdown
              className="text-part"
              text={lexicon.get('section_list_markdown_2')}
            />
          </section>
          <section className="accordion-section">
            <Markdown
              className="text-part list-intro"
              text={lexicon.get('section_intro_markdown_3')}
            />
            <Markdown
              className="text-part"
              text={lexicon.get('section_list_markdown_3')}
            />
          </section>
        </Accordion>

        <Markdown className="page-intro-text" text={lexicon.get('nitid')} />
      </div>

      <div className="desktop-only">
        <div className="image-container">
          <img
            className="partnership-image"
            src={VAIconColoredImg}
            alt="VA logo"
          />
          <img
            className="partnership-image"
            src={UWIconColoredImg}
            alt="UW logo"
          />
          <img
            className="partnership-image"
            src={UCSFIconColoredImg}
            alt="UCSF logo"
          />
          <a
            href="https://www.womenshealthresearch.pitt.edu/femtech-collaborative"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="credits-image"
              src={CONVERGEIconImg}
              alt="Converge logo"
            />
          </a>
          <img className="credits-image" src={COINIconImg} alt="COIN logo" />
          <img className="credits-image" src={CHERPIconImg} alt="CHERP logo" />
          <img
            className="credits-image"
            src={women4WomenImg}
            alt="Women 4 women veterans logo"
          />
          <img
            className="partnership-image"
            id="nitid-logo"
            src={nitidIconImg}
            alt="Nitid logo"
          />
        </div>
      </div>
    </Page>
  );
};

const AboutLexicon = new Lexicon(
  text,
  'en',
  'src/pages/About/AboutStrings.json'
);

export default withEditWrapper(About, AboutLexicon);
